header {
    padding-top: 7rem;
    overflow: hidden;
    min-height: 60rem;
}

.header__container {
    text-align: center;
    height: 70%;
    position: relative;
}


/* =================== CTA ==================== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center; 
}


/* =================== HEADER SOCIALS ==================== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    margin-left: 4rem;
    margin-top: 12rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 3rem;
    background: var(--color-primary);
}


/* =================== ME ==================== */
.me {
    background: linear-gradient(var(--color-primary), rgb(0, 0, 0));
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    padding: 6.3rem 1.5rem 1.5rem 1.5rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
}



/* ========== MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ==========*/
@media screen and (max-width: 1024px) {
    header {
        height:68vh;
    }

    .header__socials{
        display: none;
    }
}

/* Display the socials in an alternative way on mobile devices */
/* ========== MEDIA QUERIES (MEDIUM DEVICES - SMART PHONE) ==========*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials{
        display: none;
    }
}